#graph-output {
    min-height: 350px;
    max-height: 500px;
    max-width: 100%;
    border: 1px solid #ccc;
}

#graph-output svg {
    min-height: 350px;
    max-height: 500px;
    max-width: 100%;
    height: auto;
}